import React from 'react';
import "./Projects.css"
import Menu from './Menu';
import { Helmet } from 'react-helmet'




const Projects = () => {

    const text = "SOME OF MY PROJECTS".split('').map((char, index) => {
        if (char === ' ') {
            return <span key={index} style={{ width: '0.6em', display: 'inline-block' }}></span>;
        }
        return <span style={{ animationDelay: `${0.1 * index}s` }} key={index}>{char}</span>;
    });


    return (
        <div className='projects-page'>
            <Menu />
            <Helmet>
                <style>
                    {`
                        body {
                            background-image: none !important;
                            background-color: #e2d8c3 !important;
                        }
                    `}
                </style>
            </Helmet>
            <div className='projects-presentation'>
                {text}
            </div>
            <div className='project-container'>
                <div className='column'>


                    <div className='project'>
                        <a href='https://audioatlas.netlify.app/' target='_blank' rel='noopener noreferrer'>
                            <img src='./img/spotify.gif' alt='3Dcar' />
                        </a>
                        <div className='project-description'>
                            A Spotify top artists/tracks using Spotify API
                        </div>
                    </div>
                    <div className='project'>
                        <a href='https://github.com/Yaaaaannis/3d-car.io' target='_blank' rel='noopener noreferrer'>
                            <img src='./img/3d-gif.gif' alt='3Dcar' />
                        </a>
                        <div className='project-description'>
                            A 3D car scene made with Three.js Library.
                        </div>
                    </div>
                    <div className='project'>
                        <a href='https://github.com/Yaaaaannis/car' target='_blank' rel='noopener noreferrer'>
                            <img src='./img/car.gif' alt='neural network car' />
                        </a>
                        <div className='project-description'>
                            Vanilla Javascript Neural Network Car using genetic algorithm.
                        </div>
                    </div>
                    <div className='project'>
                        <a href='https://www.eklg.shop/' target='_blank' rel='noopener noreferrer'>
                            <img src='./img/eklg.png' alt='filter' />
                        </a>
                        <div className='project-description'>
                            A Website made for a new clothing brand using Wix.
                        </div>
                    </div>
                </div>
                <div className='column'>
                    <div className='project-right'>
                        <a href='https://watchlisttest.netlify.app/' target='_blank' rel='noopener noreferrer'>
                            <img src='./img/watchlist.gif' alt='brand webshop' />
                        </a>
                        <div className='project-description'>
                            A Watchlist app using NextJs,The Movie DB API and Firebase.
                        </div>
                    </div>
                    <div className='project-right'><img src='./img/screen-bec.png' alt='screen bec' />
                        <div className='project-description'>
                            React WebApp to put the work of a mechanical engineer on show
                        </div>
                    </div>
                    <div className='project-right'>
                        <a href='https://github.com/Yaaaaannis/pokemonapi' target='_blank' rel='noopener noreferrer'>
                            <img src='./img/pokeapi.gif' alt='Pokemon api' />
                        </a>
                        <div className='project-description'>
                            A random Pokemon generator using the pokemon api.
                        </div>
                    </div>
                </div>

            </div>



        </div>
    );
}

export default Projects;
