import React from 'react';
import "./Menu.css"
import {Link} from 'react-router-dom'





const Menu = () => {

    
    return (
        <div className="nav">
            <div className='wrapper'>
                <Link to="/home" className='logo'>Yannis Febvre</Link>
                <ul className='nav-links'>
                    <li><Link to="/projects" className="link">Projects</Link></li>
                    <li><Link to="/about" className="link">About</Link></li>
                    <li><Link to="/contact" className="link">Contact Me</Link></li>                    
                </ul>

            </div>
            
            
        </div>
    );
}

export default Menu;
