// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help
import React from 'react';
import {Helmet} from 'react-helmet'
import "./Contact.css"
import { useForm, ValidationError } from '@formspree/react';
import Menu from './Menu';
function ContactForm() {
  const [state, handleSubmit] = useForm("mzblwpde");
  if (state.succeeded) {
      return <div className='succeed'><p>Thanks for Your Message!</p></div>
  }



  return (
      <form onSubmit={handleSubmit}>
      <label htmlFor="email">        
      </label>
      <div className='email'>
      <input
        id="email"
        type="email" 
        name="email"
        placeholder='Your Email Adress '
      />
      </div>
      <div className='subject'>  {/* Nouvelle div pour l'objet du mail */}
    <input
      id="subject"
      type="text" 
      name="subject"
      placeholder='Subject'
    />
    </div>
      
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
      <div className='contact-project'>
      <textarea
        id="message"
        name="message"
        placeholder='Tell Me About Your Project'
      />
      </div>
      
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
      <div className='contact-btn'><button type="submit" disabled={state.submitting}>
        Send Message
      </button></div>
    </form>
  );
}
function Contact() {
  return (
    <div>
        <Menu  />
        <Helmet>
                <style>
                    {`
                        body {
                            background-image: none !important;
                            background-color: #e2d8c3 !important;
                        }
                    `}
                </style>
            </Helmet>
        <div className='contact-text'>
            Let's get things started !
        </div>
        <ContactForm />
    </div>
    
  );
}
export default Contact;