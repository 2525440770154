import './Home.css';
import Menu from './Menu';

function Home() {
  return (
    <div>
      <Menu />
      <div  className="web">
          WEB <br />
          <div className='web-br'>DEVELOPER</div>
      </div>
      <div className='web-description'>
              <h2>I'm a freelance Web Developer based in France, I love art and coding,<br /> I'm always looking for new challenges and new projects to work on.</h2>
          </div>



    </div>
    
  );
}

export default Home;
