import React, { useState, useEffect } from 'react';
import "./About.css";
import Menu from './Menu';
import { Helmet } from 'react-helmet'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';



const words = ['Today !', 'Tomorrow !', 'When You Need !']




const About = () => {

    const [index, setIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIndex(prevIndex => (prevIndex + 1) % words.length);
        }, 3000)

        return () => clearInterval(intervalId);
    }, []);

    const wordStyles = [
        { color: '#040624', fontSize: '20px', backgroundColor: '#5da7ae', borderRadius: '20px' },
        { color: 'white', fontSize: '20px', backgroundColor: '#5da7ae', borderRadius: '20px' },
        { color: '#5da7ae', fontSize: '20px', backgroundColor: 'white', borderRadius: '20px' }
    ];






    return (
        <div className='about-body'>
            <Menu />
            <Helmet>
                <style>
                    {`
                        body {
                            background-image: none !important;
                            background-color: #e2d8c3 !important;
                        }
                    `}
                </style>
            </Helmet>
            <div className="about-intro">
                <div className='about-text'>
                    <div className='about-h'>H</div>
                    <div className='about-e'>E</div>
                    <div className='about-y'>Y</div>
                </div>
                <div className='about-text-more'>
                    <div className='about-text-more-one'>Hello Im Yannis ! 19 Years Old. </div>
                    <div className='about-text-more-two'>Available : <span
                        className='changing-word'
                        style={wordStyles[index]}  // Applique les styles à chaque mot
                    >
                        {words[index]}
                    </span></div>
                    <div></div>


                </div>

            </div>
            {/* <ToggleDiv /> */}

            <div className='splide-container'>
                <Splide
                    options={{
                        type: "loop",

                        drag: "free",
                        arrows: false,

                        pagination: false,
                        perPage: 5,

                        autoScroll: {
                            pauseOnHover: false,
                            pauseOnFocus: false,
                            interval: 0,
                            speed: 2,
                        },
                        breakpoints: {
                            480: {
                                perPage: 2,
                                gap: '3px'
                            },
                        }
                    }}
                    extensions={{ AutoScroll }}
                >
                    <SplideSlide>
                        <img src='./img/css.png' alt='css' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src='./img/html.png' alt='html' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src='./img/js.png' alt='js' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src='./img/react.png' alt='react' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src='./img/wix.png' alt='wix' />
                    </SplideSlide>
                </Splide>

            </div>

            <div className='footer'>
                <div className='social-network'>
                    <div className='link-img'>
                        <a href='https://www.linkedin.com/in/yannis-febvre-ba312a226/' target='_blank' rel="noreferrer">
                            <img src='./img/linkedin.png' alt='linkedin' />
                        </a>
                    </div>
                    <div className='link-img'>
                        <a href='https://github.com/Yaaaaannis' target='_blank' rel="noreferrer">
                            <img src='./img/github.png' alt='github' />
                        </a>
                    </div>
                </div>
                Code by Yannis Febvre
            </div>

        </div >
    );
}


export default About;
